'use client' // Error boundaries must be Client Components

import React from 'react'
import { useEffect } from 'react'
import ErrorScreen from 'apps/studio-shared/src/Components/Shared/ErrorScreen'

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return <ErrorScreen error={error} reset={reset} />
}
