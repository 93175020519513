'use client' // Error boundaries must be Client Components

import Button from '@codeverse/helios/button'
import { H } from '@highlight-run/next/client'
import React from 'react'
import { useEffect } from 'react'

export default function ErrorScreen({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    H?.consumeError(error)
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-[#101432]">
      <h2>Uh oh, Something went wrong!</h2>
      <Button
        context="primaryFlat"
        size="regular"
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Restart
      </Button>
    </div>
  )
}
